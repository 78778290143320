.dashboard {
  background-color: #F5F8FA;
  flex: 1;
  flex-wrap: wrap;
  padding: 90px 20px 20px;
  height: 100%;

  .content {
    flex: 1;
    overflow: hidden;

    .search {
      height: 70px;
      padding: 10px 20px;
      background-color: #FFF;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;

      .input-group {
        height: 100%;
        border-radius: 10px;
      }
      
      .input-group-text {
        height: 100%;
        border: 0;
        color: #5E6278;
        background-color: #F5F8FA;
      }

      input {
        width: 250px;
        border: 0;
        padding: 5px;
        background-color: #F5F8FA;
        color: #5E6278;
        font-size: 12px;
      }

      .form-control:focus,
      .form-control:active {
        box-shadow: none !important;
        outline: 0px !important;
      }
    }

    .data {
      flex: 1;

      .nav-tabs {
        border: 0;

        .nav-link {
            color: #009EF7;
            border: 0;
            cursor: pointer;

            .inst-tab {
              display: flex;
              justify-content: center;
              margin-right: -5px;

              svg {
                padding: 1px;
                font-size: 15px;
                border-radius: 5px;
              }

              svg:hover {
                background-color: #F4F6FA;
              }
            }
        }
      }

      .tab-content {
        display: flex;
        flex: 1;

        .tab-pane {
          flex: 1;
          padding: 20px;
          background-color: #FFF;
          border-radius: 0 0 10px 10px;
          position: relative;

        .downloading {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
        }

          > div {
              height: 100%;
          }

          .digital {
            .section {
              font-size: 18px;
              font-weight: 700;
              color: #181C32;
            }

            .label {
              font-size: 15px;
              font-weight: 600;
              color: #181C32;
            }

            .top-label {
              padding-right: 10px;
            }
            .records {
              color: #5E6278;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .runsheet {
          display: flex;

          .runsheet-details {
            background-color: red;
            width: 100%;
            height: 15%;
          }

          .add {
            position: absolute;
            right: 50px;
            bottom: 60px;
            z-index: 10;
            font-size: 30px;
            border-radius: 50%;
            width: 58px;
          }
        }

        .floater {
          position: absolute;
          right: 310px;
          top: 28px;
          z-index: 10;
          font-size: 22px;
          color: #666;
        }
        .floater:hover {
          color: #666;
          background-color: #d6d6d6;
          border-radius: 5px;
        }

        .pdf-container {
          display: flex;

          .pdf {
            width: 60%;
          }  
        }
      }

      .ag-header-cell-text {
        color: #000000DE;
      }

      .ag-cell {
        a {
          font-size: inherit;
        }

        .check {
          cursor: pointer;
          z-index: 10;
          position: relative;
        }
      }
    }
  }

  .edit-instrument {
    z-index: 99;
    position: absolute;
    right: 20px;
    width: calc(100% - 20px);
}

}

.dashboard-dark {
  background-color: #0D0E12;
  flex: 1;
  flex-wrap: wrap;
  padding: 90px 20px 20px;
  height: 100%;

  .edit-instrument {
    position: absolute;
    right: 20px;
    width: calc(100% - 20px);

    .downloading{
      color: #fff;
    }

    .btn-primary {
      color: #121212;
      background-color: #9dd1ff;
      border: none;
      }
    
      .btn-primary:hover {
        background-color: #6cc0ff;
      }
  } 
  .ag-list-item > span{
    color: #0D0E12;
  }

  span{
    color: white;
  }

  .content {
    flex: 1;

    .search {
      height: 70px;
      padding: 10px 20px;
      background-color: #1e1e2d;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .btn-primary 
      .input-group {
        height: 100%;
        border-radius: 10px;
      }
      
      .input-group-text {
        height: 100%;
        border: 0;
        color: #5E6278;
        background-color: #1b1b29;
      }

      input {
        width: 250px;
        border: 0;
        padding: 5px;
        background-color: #1a1625;
        color: #ffffff;
        font-size: 12px;
      }

      .form-control:focus,
      .form-control:active {
        box-shadow: none !important;
        outline: 0px !important;
      }
    }

    .data {
      flex: 1;

      .nav-tabs {
        border: 0;

        .nav-link.active {
          background-color: #1e1e2d;;
        }

        .nav-link {
            color: #009EF7;
            border: 0;
            cursor: pointer;

            .inst-tab {
              display: flex;
              justify-content: center;
              margin-right: -5px;

              svg {
                padding: 1px;
                font-size: 15px;
                border-radius: 5px;
              }

              svg:hover {
                background-color: #F4F6FA;
              }
            }
        }
      }

      .tab-content {
        display: flex;
        flex: 1;

        .tab-pane {
          flex: 1;
          padding: 20px;
          background-color: #1e1e2d;
          border-radius: 0 0 10px 10px;
          position: relative;

        .downloading {
          color: #FFF;
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
        }

          > div {
              height: 100%;
          }

          .digital {
            .section {
              font-size: 18px;
              font-weight: 700;
              color: #ffffff;
            }


            .label {
              font-size: 15px;
              font-weight: 600;
              // color: #181C32;
              color: #FFF;
            }

            .top-label {
              padding-right: 10px;
            }
            .records {
              color: #a7a9b3;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .runsheet {
          display: flex;

          .runsheet-details {
            background-color: red;
            width: 100%;
            height: 15%;
          }

          .add {
            position: absolute;
            right: 50px;
            bottom: 60px;
            z-index: 10;
            font-size: 30px;
            border-radius: 50%;
            width: 58px;
          }
        }

        .floater {
          position: absolute;
          right: 310px;
          top: 28px;
          z-index: 10;
          font-size: 22px;
          color: #eaeaea;
        }
        .floater:hover {
          color: #eaeaea;
          background-color: #191919;
          border-radius: 5px;
        }

        .pdf-container {
          display: flex;

          .pdf {
            width: 60%;
          }
 
        }
      }

      .ag-header-cell-text {
        color: #FFF;
      }

      .ag-cell {
        a {
          font-size: inherit;
        }

        .check {
          cursor: pointer;
          z-index: 10;
          position: relative;
        }
      }
    }
  }
  .pdf {
    position: absolute;
    inset: 20px;
    height: auto !important;
}

.btn-rotate {
    display: flex;
    z-index: 10;
    position: absolute;
    top: 4px;
    right: 215px;
}

  .pdf-false-dark{
    .btn-dark {
        background: transparent;
        color: #fff;
    
        button{
            color: #fff;
        }
      }
      
      .btn-light {
        background: transparent;
        color: #000;
      }
      
      .btn-dark:hover {
        background-color: #191919;
        border-radius: 5px;
      }
      
      .btn-light:hover {
        background-color: #d6d6d6;
        border-radius: 5px;
      }
  }
  
  .pdf-modal{
    .btn-dark {
        background: transparent;
        color: #fff;
    
        button{
            color: #fff;
        }
      }
      
      .btn-light {
        background: transparent;
        color: #000;
      }
      
      .btn-dark:hover {
        background-color: #191919;
        border-radius: 5px;
      }
      
      .btn-light:hover {
        background-color: #d6d6d6;
        border-radius: 5px;
      }
  }

}

.ag-header-cell {
  padding-right: 10px;
  padding-left: 15px;
}

.ag-cell {
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-cell-wrapper {
  word-wrap: break-word;
  white-space: normal;
}

.btn-dark {
  background-color: #212e48;
}