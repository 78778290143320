.form {
  width: 500px;
  padding: 30px 100px 29px 80px;
  display: inline-block;
}

.login .form input {
  line-height: 19.8px;
  width: 320px;
  margin-top: 7px;
}

.label {
  color: #181c32;
  font-size: 16px;
  font-weight: 700;
  margin: 10px;
}

.info {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.text-error {
  color: red;
}

.dark .label {
  color: white; /* Ensure text appears white in dark mode */
}
