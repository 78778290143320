.navbar {
  inset: 0;
  position: fixed;
  z-index: 1000;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  // -webkit-box-shadow: 2px 2px 6px 0 #999;
  // -moz-box-shadow: 2px 2px 6px 0 #999;
  // box-shadow: 3px 3px 6px 0 #999;

  .left {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    height: 100%;
  }

  .btn-menu {
    background-color: #F4F6FA;
    color: #009EF7;
  }

  .controls {
    display: flex;

    .dropdown:hover .dropdown-menu {
      display: flex;
      flex-direction: column;
      padding: 10px;
      top: 30px;
      right: 0;

      .profile {
        .name {
          font-weight: 700;
        }

        .email {
          font-size: 12px;
          color: #5E6278;
        }
      }
      
      .btn-micon {
        font-size: 12px;
        color: #181C32;
        flex: 1;
      }
      
      .btn-micon:hover {
        background-color: #F5F8FA;
        color: #009EF7;
      }
    }
  }
}

.navbar-dark {
  inset: 0;
  position: fixed;
  z-index: 1000;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1e2d;

  .btn {
    color: white;
  }
  .label{
    color: white;
  }

  .btn-menu {
    background-color: #F4F6FA;
    color: #009EF7;
  }

  .controls {
    display: flex;

    .dropdown:hover .dropdown-menu {
      display: flex;
      flex-direction: column;
      padding: 10px;
      top: 30px;
      right: 0;

      .profile {
        .name {
          color: #F4F6FA;
          font-weight: 700;
        }
    
        .email {
          font-size: 12px;
          color: #009EF7;
        }
      }
      
      .btn-micon {
        font-size: 12px;
        color: #F5F8FA;
        flex: 1;
      }
      
      .btn-micon:hover {
        background-color: #1e1e2d;
        color: #ffffff;
      }
    }
  }
  .dropdown-menu{
    background-color: #46424f;
  }
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 20px;
}

.mt--5 {
  margin-top: -5px;
}