.report-head {
  align-items: center !important;
  justify-content: center !important;
  color: #61c2f6;
  font-size: xx-large;
  font-family: sans-serif;
}
.admin-header {
  border-radius: 10px;
  background-color: white;
  height: 120px;
}
/* Style for the custom list */
.custom-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
  
.custom-list-item {
  line-height: 1.5;
  white-space: pre-wrap; /* Ensure text wraps properly */
}

.custom-list-item span {
  display: inline-block;
  margin-bottom: 2px; /* Adjust spacing within wrapped text */
}

.daily-report .ag-cell-value {
  line-height: 1.5;
  word-wrap: break-word;
  word-break: normal;
}

.daily-report {
  .ag-cell {
    display: flex;
    align-items: center;
  }
}
.report-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.report-head .report-date {
  margin-top: 4px;
  font-size: 0.9rem;
  color: #61c2f6;
}

.daily-report-edit-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    display: flex;
    flex-direction: column;
    height: 80vh; // Set a fixed height for the modal
    max-height: 90vh; // Optional: To handle very small screens

    .modal-header,
    .modal-footer {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white; // Ensure background color to avoid transparency
      border-bottom: 1px solid #dee2e6;
    }

    .modal-footer {
      bottom: 0;
      border-top: 1px solid #dee2e6;
    }

    .modal-body {
      flex: 1; // Allow the body to take the remaining space
      overflow-y: auto; // Enable vertical scrolling in the body
    }

  }
}

.dialog-dark{
  .modal-header, .modal-footer{
    background-color: #46424f !important;
  }

  .form-control{
    background-color: #1e1e2d;
  }
}

.dashboard-dark{
  .admin-header{
    background-color: #1e1e2d;
  }

  .dropdown-menu{
    background-color: #46424f;
  }

  .dropdown-item{
    color: #ffffff;
  }

  .dropdown-item:hover{
   background-color: #1e1e2d;
  }
}

.ag-cell {
  white-space: normal !important;
  word-break: keep-all !important;
  overflow-wrap: break-word !important;
}
