.btn {
    font-size: 14px;
}

.btn:focus,
.btn:active,
.form-control:focus,
.form-control:active {
    box-shadow: none !important;
    outline: 0px !important;
}

.btn-primary {
    color: #fff;
    background-color: #26ADF8;
    border-color: #26ADF8;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #009EF7;
    border-color: #009EF7;
}

.btn-primary:disabled, .btn-primary.disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: #6d7177;
    border-color: #6d7177;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    pointer-events: auto;
    opacity: 0.65;
}


// .btn-info:hover{
//     background-color: #009EF7;
//     color: #009EF7;
//     border: 1px solid #009EF7;
// }

.btn-error,
.btn-error:hover,
.btn-error:focus {
    background-color: #c53522;
    color: #ffffff;
    border: 1px solid #ffffff;
}

// .btn-info {
//     border: 1px solid #F4F6FA;
// }

.btn-link {
    text-decoration: none;
}

.btn-icon {
    cursor: pointer;
    font-size: 20px;
    color: #181c32;
}

.btn-icon:hover {
    background-color: #F5F8FA;
    color: #009EF7;
}

.form-control {
    font-size: 14px;
}


.search-filter-dark {
    .btn-primary {
    color: #121212;
    background-color: #9dd1ff;
    border: none;
    }

    .btn-primary:hover {
      background-color: #6cc0ff;
    }

    .btn-icon {
        cursor: pointer;
        background-color: #46424f;
        font-size: 20px;
        color: #F4F6FA;
    }
    
    .btn-icon:hover {
        background-color: #1e1e2d;
        color: #009EF7;
    }
    
  }
  
//   .btn-info {
//     color: #bfbfbf;
//     background-color: #1e1e2d;
//     border: none; 
//     color: rgba(255, 255, 255, 0.8);
//     border-radius: 4px;
//     transition: all 0.2s ease-in-out;
//   }
  
//   .btn-info:hover {
//     color: rgba(255, 255, 255, 1);
//   }

  .dashboard-dark{
    .btn-icon {
        cursor: pointer;
        background: transparent;
        font-size: 20px;
        color: #F4F6FA;
    }
    
    .btn-icon:hover {
        // background-color: #1e1e2d;
        color: #009EF7;
    }

    .btn-primary {
        color: #121212;
        background-color: #9dd1ff;
    }
    .btn-primary:hover {
        color: #121212;
        background-color: #6cc0ff;
    }
  }
  
  .body-light{
    
    .btn-info {
        background-color: #f4f6fa;
        color: #009ef7;
        border: 1px solid#f4f6fa;
    }
      
    .btn-info:hover {
        color: #009ef7;
        background-color: #f4f6fa;
        border: 1px solid #009ef7;
    }
} 

.body-dark{
    
    .btn-info {
        background-color: #46424f;
        color: #F4F6FA;
        border: 1px solid #46424f;
    }
      
    .btn-info:hover {
        color: #F4F6FA;
        background-color: #46424f;
        border: 1px solid #F4F6FA;
    }

    .btn-primary {
        color: #121212;
        background-color: #9dd1ff;
        border: none;
        }
      
    
        .btn-primary:hover {
          background-color: #6cc0ff;
        }
    
}

.dialog{
    .btn-info {
        background-color: #f4f6fa;
        color: #009ef7;
        border: 1px solid#f4f6fa;
    }
      
    .btn-info:hover {
        color: #009ef7;
        background-color: #f4f6fa;
        border: 1px solid #009ef7;
    }
} 

.modal-open{
    .body-light{
    
        .btn-info {
            background-color: #f4f6fa;
            color: #009ef7;
            border: 1px solid#f4f6fa;
        }
          
        .btn-info:hover {
            color: #009ef7;
            background-color: #f4f6fa;
            border: 1px solid #009ef7;
        }
    } 
    
    .body-dark{
        
        .btn-info {
            background-color: #46424f;
            color: #F4F6FA;
            border: 1px solid #46424f;
        }
          
        .btn-info:hover {
            color: #F4F6FA;
            background-color: #46424f;
            border: 1px solid #F4F6FA;
        }
    }
    
    .create-runsheet{
        .btn-info {
            background-color: #f4f6fa;
            color: #009ef7;
            border: 1px solid#f4f6fa;
        }
          
        .btn-info:hover {
            color: #009ef7;
            background-color: #f4f6fa;
            border: 1px solid #009ef7;
        }
    }

    .create-runsheet-dark{
        .btn-info {
            background-color: #46424f;
            color: #F4F6FA;
            border: 1px solid #46424f;
        }
          
        .btn-info:hover {
            color: #F4F6FA;
            background-color: #46424f;
            border: 1px solid #F4F6FA;
        }

        .btn-primary {
            color: #121212;
            background-color: #9dd1ff;
            border: none;
        }
        
        .btn-primary:hover {
            background-color: #6cc0ff;
        }


    }
}
    
.btn-info {
    background-color: #46424f;
    color: #F4F6FA;
    border: 1px solid #46424f;
}
  
.btn-info:hover {
    color: #F4F6FA;
    background-color: #46424f;
    border: 1px solid #F4F6FA;
}


.modal{
    .edit-instrument{
        
            .btn-info {
                background-color: #f4f6fa;
                color: #009ef7;
                border: 1px solid#f4f6fa;
            }
              
            .btn-info:hover {
                color: #009ef7;
                background-color: #f4f6fa;
                border: 1px solid #009ef7;
            }
        
    }

    .edit-instrument-dark{
       
        .btn-info {
            background-color: #46424f;
            color: #F4F6FA;
            border: 1px solid #46424f;
        }
          
        .btn-info:hover {
            color: #F4F6FA;
            background-color: #46424f;
            border: 1px solid #F4F6FA;
        }
        
    }
}