.map {

    &.dark {
        // background-color:#F2F1ED;
        filter: invert(100%);

        .info-icon {
            .icon {
                color: #cdbaba;
            }
        }
    }
}

.info-icon {
    position: absolute;
    top: 160px;
    right: 50px;
    z-index: 999;
    cursor: pointer;

    .icon {
        font-size: 1.5rem;
        color: #333;
    }
}

.esri-view-surface::after {
    content: none !important; /* Ensure no additional styling is applied */
}

.esri-component .esri-attribution .esri-widget {
    display: none !important;
}
.esri-ui-manual-container>.esri-component {
    display: none;
}

.esri-ui-corner{
    align-items: flex-end;
    right: 30px;
}

.dashboard-dark{
    .info-icon {
        position: absolute;
        top: 160px;
        right: 50px;
        z-index: 999;
        cursor: pointer;
    
        .icon {
            font-size: 1.5rem;
            color: #fff;
        }
    }
}