.table {
  flex: 1;
  position: relative;

  > div {
    flex: 1;
    position: relative;
  }

  .ag-cell {
    transition: background-color 0.3s ease;
  }

  .download-icon {
    top: -45px;
    z-index: 10;
    right: 40px;
    font-size: 20px;
    position: absolute;
    border-color: black;
    background-color: #d9d9dd;
    padding: 4px;
  }

  /* Dark Mode Styles */
  &.dark {
    .ag-cell,
    .ag-row:hover .ag-cell {
      background-color: #46424f;
      color: #d9d9dd; 
    }

    .ag-row:hover .ag-cell:hover {
      background-color: #1a1625;
    }

    .download-icon {
      background-color: #26272f;
      color: white;
      border-color: black;
    }

    .ag-header-container .ag-icon{
      color: #d9d9dd;
    }
    .ag-header-container {
      background-color: #1a1625;

     

      .ag-header-cell-text {
        color: #d9d9dd;
      }
    }

    /* Prevent header cell background from changing on hover */
    .ag-header-cell:hover {
      background-color: #1a1625 !important;
    }

    /* Ensure header cell text color remains consistent on hover */
    .ag-header-cell:hover .ag-header-cell-text {
      color: #d9d9dd !important;
    }
  }
}

// .ag-body-horizontal-scroll-viewport {
//   overflow-x: hidden; 
// }

.loader {
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #00aeff;
  border-right-color: #00aeff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.loading-spinner-wrapper span {
  position: absolute;
  color: #00aeff;
  font-weight: bold;
  font-size: 1.5em; /* Adjust size of the text */
}