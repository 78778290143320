.dashboard-dark{
  .profile-container {
    width: 50%;
    padding: 20px;
    border-radius: 8px;
    background-color: #46424f; /* Enhanced shadow */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  
    h2 {
      color: #ffffff;
      font-size: 1.8rem;
      margin-bottom: 20px;
    }
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
  
      .form-control {
        color: #ffffff;
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
  
        &:focus {
          border-color: #007bff; /* Make border blue on focus */
          box-shadow: 0 0 8px rgba(251, 31, 31, 0.6); /* Add stronger blue glow */
          outline: none;
          background-color: #f9fbff; /* Optional: Slight background color change */
        }
      }
    }
  
    .error {
      color: red;
      font-size: 0.875rem;
      margin-top: 5px;
    }
  
    .change-password-btn {
      margin-top: 20px;
      padding: 8px 16px;
      font-size: 1rem;
      cursor: pointer;
      float: right;
    }
  }
}

.profile-container {
  width: 50%;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow */

  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .form-control {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        border-color: #007bff; /* Make border blue on focus */
        box-shadow: 0 0 8px rgba(251, 31, 31, 0.6); /* Add stronger blue glow */
        outline: none;
        background-color: #f9fbff; /* Optional: Slight background color change */
      }
    }
  }

  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }

  .change-password-btn {
    margin-top: 20px;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    float: right;
  }
}

// Modal styles
.modal-form {
  .form-control {
    margin-bottom: 10px;
  }

  .error {
    color: red;
    font-size: 0.875rem;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .profile-container {
    padding: 10px;

    h2 {
      font-size: 1.5rem;
    }
  }

  .update-button-container .btn {
    bottom: 20px;
    right: 10px;
    width: 100%;
  }
}

.update-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
}
