.modal {
  .show{
    margin-top: 10%;
  }
    .modal-title {
        font-size: 15px;
        font-weight: 700;
        color: #181C32;
    }

    .modal-body {
        color: #181C32;
        font-size: 12px;
        font-weight: 500;
    }
    
}

.dialog-dark {
    
    color: #fff;
    margin-top: 10%;

    .modal-title{
      color: #fff;
    }
    
    .form-control{
    color: #fff;
   }

   .btn-primary {
    color: #121212;
    background-color: #9dd1ff;
    border: none;
    }
  
    .btn-primary:hover {
      background-color: #6cc0ff;
    }
  }

  
  .dialog-dark .modal-header {
    background-color: #1e1e2d;
    color: #fff;
    border-color: #454d55;
  }
  
  .dialog-dark .modal-body {
    color: #fff;
  }
  

  .dialog-dark{
    input{
      color-scheme: dark;
    }
  }

  .dialog-dark .modal-footer {
    background-color: #1e1e2d;
    color: #fff;
    border-color: #454d55;
  }

  .dialog-dark{
    .modal-body{
        background-color: #1e1e2d;
    }

    .header{
      color:#fff;
        background-color: #1e1e2d;
    }

    label{
        color: #fff;
    }

    input{
      color: #fff;
      color-scheme: dark;
    }

    .react-tagsinput{
        color: #fff;
        background-color: #1e1e2d;
    }

    h1 {
      color: #fff !important;
      font-weight: 700 !important;
      font-size: 1.3rem;
    }
  }

// modal.scss
.custom-modal .modal-dialog {
  transition: transform 0.3s ease-out;
}

.custom-modal .modal-dialog.modal-dialog-centered {
  transform: translateY(-50%);
}

.custom-modal .modal-dialog.show {
  transform: translateY(0);
}