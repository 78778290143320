.edit-instrument {
    width: 36%;
    right: 20px;
    position: absolute;
    inset: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #FFF;

    .header {
        color: #009EF7 !important;
        font-weight: 500;
        font-size: larger;
    }

    .content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .county-select{
            width: 80%;
            max-width: 500px;
        }

        label {
            margin: 20px 0 2px;
        }

        label:first-child {
            margin: 5px 0 2px;
        }

        textarea {
            height: 75px;
            width:80%;
            max-width: 500px;
            resize: none;
        }

        .error-input{
            width:80% !important;
            max-width: 500px !important; 
        }

        .form-control{
            width: 80% !important;
            max-width: 500px; 
        }

        .react-tagsinput {
            border-radius: 0.25rem;
            display: inline-table;
            width: 80%;
            max-width: 500px;

            .react-tagsinput-tag {
                background-color: #F5F8FA;
                border-color: #CCC;
                border-radius: 0.25rem;
                color: #181C32;
            }

            .react-tagsinput-input {
                width: 80%;
                max-width: 500px;
    
            }
        }

        .react-tagsinput--focused {
            border-color: #86B7FE;
        }
    }  

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        margin-right: 20px;

        .btn {
            margin-left: 10px;
            width: 70px;
        }
    }
}

.edit-instrument-dark{
    z-index: 99;
    right: 20px;
    position: absolute;
    inset: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #1e1e2d;

   &.error{
    .form-control{
        width: 100%;
    }
   }

    .county-select{
        // background-color: #1e1e2d;
        width: 80%;
        max-width: 500px;
    }

    input{
        color: #F5F8FA;
    }

    .form-control{    
        max-width: 500px;
        background-color: #1e1e2d;
    }

    .content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .form-control{
            width: 80%;
            max-width: 500px;
        }
        
        .form-control:focus {
            color: #F5F8FA;
        }

        label {
            margin: 20px 0 2px;
            color: #F5F8FA;
        }

        label:first-child {
            margin: 5px 0 2px;
        }

        textarea {
            color:#F5F8FA;
            height: 75px;
            width: 98%;
            resize: none;
        }
    }

    .react-tagsinput{
        width: 80%;
        max-width: 500px;
        background-color: #1e1e2d;
        display: inline-table;
    }

    .react-tagsinput-tag {
        background-color: #46424f;
        border-color: #CCC;
        border-radius: 0.25rem;
        color: #F5F8FA;
    }

    .filter{
        width: 80%;
        max-width: 500px;
        background-color: #1e1e2d;
    }
    
    .css-ldu25h-control{
        background-color: #1e1e2d;
        color: #F5F8FA;
        &:focus{
            background-color: #1e1e2d;
        }
    }

    .css-mx7tbh-control{
        background-color: #1e1e2d;
        color:#F5F8FA;
        &:hover{
            background-color: #1e1e2d;
            color:#F5F8FA;
        }
    }

    .css-qav5hl-singleValue{
        color: #F5F8FA;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        margin-right: 20px;

        .btn {
            margin-left: 10px;
            width: 70px;
        }
    }
}