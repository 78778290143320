.toast {
    justify-content: center;

    .button {
        top: -2px;
        right: 185px;
        position: absolute;
    }
}

.button-icon {
    top: 90px;
    position: absolute;
    right: 55px;
    z-index: 10;

    .icon {
        font-size: 35px;
        padding: 5px;
        border-radius: 6px;
    }
}

// .info-icon {
//     cursor: pointer;
//     top: 160px;
//     position: absolute;
//     right: 55px;
//     // z-index: 10;

//     .icon {
//         font-size: 35px;
//         padding: 5px;
//         border-radius: 6px;
//     }
// }