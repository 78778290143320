.chat {
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #c7e5f7;
    width: 80%;
  }

  .edit-instrument .buttons{
    margin-right: 0;
  }
  
  .self {
    align-self: flex-end;
    background-color: #F1F1F1;
  }
  
  .author {
    font-size: 10px;
    font-weight: bold;
  }
  
  .message {
    margin-top: 5px;
  }
  
  .time {
    font-size: 0.8em;
    color: #888888;
  }
  
  .edit-instrument-dark{
    .form-control{
      color: #fff;
    }
  }