.modal-body {
  .pdf-modal {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .downloading-center {
      font-size: 15px;
    }
  }
  .pdf-container {
    display: flex;
    .pdf {
      width: 60%;
    }

    .downloading-center {
      width: 60%;
    }

    .edit-instrument {
      width: 36%;
      left: auto;
      right: 20px;
    }

    .edit-instrument-dark {
      width: 36%;
      left: auto;
      right: 20px;

      .btn-primary {
        color: #121212;
        background-color: #9dd1ff;
        border: none;
      }

      .btn-primary:hover {
        background-color: #6cc0ff;
      }
    }
  }
}

.floater-btn {
  position: absolute;
  right: 305px;
  top: 28px;
  z-index: 10;
  font-size: 22px;
  color: #666;
}

.floater-btn:hover {
  color: #666;
  background-color: #d6d6d6;
  border-radius: 5px;
}

.custom-close-button {
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;

  .cross-icon {
    font-size: 20px;
  }
}

.modal-xl {
  max-width: 150vh;
}

// .downloading-center {
//   font-size: 15px;
//   display: flex;
//   height: 100%;
//   justify-content: center;
//   align-items: center;
// }

.report-btn {
  position: absolute;
  right: 50px;
}

.modal-dark {
  .modal-content {
    background-color: #1e1e2d;
  }

  .modal-header {
    button {
      color: #f5f8fa;
    }
  }
  .modal-title {
    color: #f5f8fa;
  }

  input{
    color-scheme: dark;
  }
}


.pdf-false-dark{
  .floater-btn:hover{
    background-color: #191919;
    border-radius: 5px;
    position: absolute;
    right: 305px;
    top: 28px;
    z-index: 10;
    font-size: 22px;
    color: #666;
  }

  .downloading-center{
    color: #fff;
  }
}
.pdf-modal{
  .content{
    margin-left: 10px;
   }
   .error-input{
    min-width: 80% !important;
   }
}