.add {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 10;
  font-size: 30px;
  border-radius: 50%;
  width: 58px;
}

.mr-3 {
  margin-right: 10px;
}

.filterContent {
  width: 840px;
}


.filterDialog {
  display: flex;
  justify-content: center;
}