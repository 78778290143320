body {
  font-family: Helvetica, "sans-serif" !important;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.body {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  color: #181C32 !important;
  font-weight: 700 !important;
  font-size: 1.3rem;
}

a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  color: #009EF7;
}

label {
  color: #181C32;
  font-weight: 700 !important;
  font-size: 12px !important;
}

.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.slow-transition {
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

.noOverflow {
  overflow: hidden;
}

.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.s-transition {
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #00000066;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #00000099;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
  }
}

.dashboard-dark{
  label{
    color: #ffffff;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
  input{
    color-scheme: dark;
  }
}