.forgot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: transparent; 
}

.forgot-box h1 {
    margin-bottom: 20px;
}

.forgot-box label {
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
}

.forgot-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: transparent;
    box-shadow: none;
    outline: none;
}

.forgot-box input:focus {
    border: 1px solid #9dd1ff;
}


.message-container {
  text-align: center;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
  background-color: #ffffff; /* White background color */
  
  .btn-primary{
    width: auto;
  }
}

.forgot-box h1 {
  font-size: 20px;
  color: #333333; /* Dark title color */
  margin-bottom: 20px;
}

.message-container p,
.forgot-box p {
  font-size: 16px;
  color: #666666; /* Medium color for message */
}

.button {
  width: 100%;
}

.forgot-container-dark{
  // background-color: #2e3339;
  // padding: 30px;
  // border-radius: 4px;

  .forgot-h1, .forgot-label, input{
    color: #ffffff !important;
  }

  .forgot-box{
    padding: 30px;
    border-radius: 4px;
    background-color: #2e3339;
  }

  .message-container {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    background-color: #2e3339; /* White background color */
    
    .btn-primary{
      width: auto;
    }
  }
  
  .message-container p,
  .forgot-box p {
    font-size: 16px;
    color: #ffffff; /* Medium color for message */
  }
  
}