.fixed-height {
    height: 600px;
    overflow-y: scroll;
}

.error {
    color: red;
}

.create-runsheet-dark {
    background-color: #1e1e2d;

    .react-select__control{
        background-color: #1e1e2d;
    }

    .modal-header .modal-title {
        color: #FFF;
    }

    .form-control {
        color: #d9d9dd;
    }

    input{
        color-scheme: dark;
    }

    form {
        .modal-body {
            .label {
                label {
                    color: #FFF !important;
                }
            }
        }

        .county-select {
            .dark-mode-select {
                background-color: #333;
                color: #fff;
                border: 1px solid #444;

                .select__control {
                    background-color: #333;
                    color: #fff;
                }

                .select__menu {
                    background-color: #444;
                }
            }
        }
    }

    .react-select__single-value{
        color: #d9d9dd;
    }
}
