.toast {
  justify-content: center;

  .button {
    top: -2px;
    right: 185px;
    position: absolute;
  }
}

.data {
  background-color: #f5f8fa;
  position: relative;
  .button-icon {
    top: 25px;
    position: absolute;
    right: 35px;
    z-index: 10;

    .icon {
      font-size: 35px;
      padding: 5px;
      border-radius: 6px;
    }
  }
}

.info-box {
  position: relative;
  height: 50px;
  background-color: #fff;
  display: flex;
  .tags {
    left: 10px;
    width: 1500px;
    position: relative;
    top: 8px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .tags.scrollable {
    overflow-x: auto;
  }

  .section {
    left: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #181c32;
    position: absolute;
    top: 15px;
  }
  .icon {
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    border-color: black;
    background-color: #d9d9dd;
    padding: 4px;
  }
}

.split-table {
  height: 100%;
}

.split {
  width: 98vw;
  height: 100%;
}

.separator {
  border-left: 2px solid #343a40;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  position: relative;
  background-color: #d9d9dd;
  cursor: row-resize;
  height: 5px !important;
  z-index: 10;
}

.control-panel {
  position: absolute;
  top: 150px;
  right: 35px;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 6px 15px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  z-index: 0 !important;
}

.control-panel label {
  font-size: 13px;
  margin: 0px;
}

.control-panel hr {
  margin-top: 0px;
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
  overflow-x: auto;
  min-height: 48px;
  padding-bottom: 10px;
}
.ReactTags__selected {
  display: flex;
  max-height: 38px;
}
/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: none;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid $info;
  background: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
  white-space: nowrap;
}
div.ReactTags__selected a.ReactTags__remove {
  color: $info;
  margin-left: 5px;
}

.ReactTags__remove {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 18px;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
.expand {
  font-size: 20px;
  right: 20px;
  top:100px;
  position: absolute;
  border-color: black;
  background-color: #d9d9dd;
  padding: 4px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0px 0px 15px 24px;
}

.dashboard-dark .info-box {
background-color: #46424f;
}

.dashboard-dark .section{
  color: #d9d9dd;
}

.dashboard-dark div.ReactTags__selected span.ReactTags__tag {
  background: #1a1625;
  color: #d9d9dd;
  border: none;
}

.dashboard-dark .ReactTags__remove{
  color: #d9d9dd;
}

.dashboard-dark .control-panel {
  position: absolute;
  top: 150px;
  right: 35px;
  max-width: 320px;
  background: #B9BDB0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 6px 15px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  outline: none;
  z-index: 0 !important;

}

.control-panel label {
  font-size: 13px;
  margin: 0px;
  color: #181c32;
}

.control-panel hr {
  margin-top: 0px;
}
.check-pointer.clicked {
  color: purple;
}

.btn-download{
  margin-right: 35px;
}

.split{
  .tab-pane{
    padding: 0 !important;
  }
}

.dashboard-dark{
  .split{
  .data{
    background-color: #46424f;
  }
}
}

.Apps-Orders {
  .ag-cell{
    display: flex;
    align-items: center;;
  }

  .ag-cell-value {
    line-height: 1.5;
}
}

.dashboard-dark{
  .data{
    background-color: #0d0e12;
  }

}

.ag-center-cols-viewport {
  height: calc(100% + 1000px) !important;
}