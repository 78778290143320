.search-filter-view{
    margin: auto;
    .form-control{
        font-size: 15px;
    }

    .d-flex, .ggTogether, .trsm, .dates,.county, .instrument-type,.input-fields{
        margin: auto;
    }

    .d-flex{
        margin-top: 10px;
    }
    .county{
        margin-top: 0%;
    }

    .county-filter{
        margin-top: 25px;
    }
}
