.search-filter {
    position: absolute;
    top: 75px;
    display: flex;
    background: #FFF;
    width: 820px;
    height: 500px;
    z-index: 1000;
    flex-direction: column;
    box-shadow: 2px 6px 25px rgba(0, 0, 0, 0.6) !important;
    transition: 0.5s;
    border-radius: 5px;
    font-size: 14px;
    
    // .react-select__control{
    //   background-color: #1a1625;
    // }
}

.search-filters:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.filters-topheader {
    left: 820px;
    color: #fff;
    position: fixed;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: 0.5s;
}

.settingbtn {
    position: absolute;
    // top: 150px;
    background-color: rgb(0, 174, 255);
    border: none;
    padding: 15px 15px;
    color: #fff;
    cursor: pointer;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px
}

.filters {
    background: #bfc3c5;
}

.dates {
  margin: 15px 0px 0px 25px;
  width: 375px;
  text-align: center;
}

.grantees {
  margin: 20px 0px 0px 20px;
  width: 380px;
  text-align: center;

  .form-control {
    margin-top: 16px;
    height: 38px;
  }
}

.input-fields {
  margin: 15px 0px 0px 20px;
  width: 180px;
  text-align: center;

  .form-control {
    margin-top: 16px;
    height: 38px;
  }
}

.trsm {
  margin: 15px 0px 0px 20px;
  width: 780px;
  text-align: center;

  .form-control {
    margin-top: 16px;
    height: 38px;
  }
}

.check-box {
  margin: 5px 0px 0px 20px;
}

.or {
  margin: 30px 0px 0px 20px;
  width: 90px;
  text-align: center;

  .arrow {
    position: absolute;
    margin: 8px;
    z-index: -10;
  }

  .filter {
    margin-top: 38px;
  }
}

.county {
  margin: 15px 0px 0px 20px;
  width: 380px;
  text-align: center;

  .arrow {
    position: absolute;
    margin: 8px;
    left: 300px;
    z-index: -10;
  }

  .filter {
    margin-top: 16px;
  }
}

.instrument-type {
  margin: 15px 0px 0px 20px;
  width: 380px;
  text-align: center;

  .arrow {
    position: absolute;
    margin: 8px;
    left: 730px;
    z-index: -10;
  }

  .filter {
    margin-top: 16px;
  }
}

.ggTogether {
  margin: 20px 0px 0px 20px;
  width: 780px;
  text-align: center;

  .form-control {
    margin-top: 16px;
    height: 38px;
  }
}

.date-filter {
  width: 182px;
}

.header {
  background-color: #d8dbe0;
  padding: 3px 0px 3px 0px;
  border-radius: 5px;
}

.separateGG {
  margin-top: 5px;
}

.check-pointer {
  cursor: pointer;
}

.buttons {
  // position: absolute;
  margin-top: 20px;

  .right {
    position: absolute;
    right: 30px;
  }

  .left {
    position: absolute;
    left: 20px;
  }
}

.slider {
  margin: 30px 0px 0px 20px;
  text-align: center;

  .slider-btn {
    width: 75px;
    height: 75px;
    font-size: 22px;
  }
}

.advance-edit {
  height: 250px;
  overflow-y: scroll;

  .advance-edit-content {
    margin-bottom: 10px;
  }
}

.advance-edit-content > div {
  display: inline-block;
}

.advance-edit-content.error {
  .select {
    border: 1px solid darkred;
    border-radius: 4px;
  }

  input {
    border: 1px solid darkred;
  }

  .error {
    display: block;
  }
}

.TRSM-advanced {
  width: 410px;
}

.react-tagsinput {
  border-radius: 0.25rem;
  width: 100%;
  display: flex;

  .react-tagsinput-tag {
      background-color: #F5F8FA;
      border-color: #CCC;
      border-radius: 0.25rem;
      color: #181C32;
  }

  .react-tagsinput-input {
      width: auto;
  }
}

.react-tagsinput--focused {
  border-color: #86B7FE;
}

.react-select__value-container {
  max-height: 30px;
  overflow: auto !important;
}

.nameTagSize {
  width: 300px !important;
}
// dark - mode
.search-filter-dark {
  background-color: #1a1625;

  .react-select__multi-value__label{
    color: #ffffff;
  }

  .date-search > div {
    color: white;
  }

  .header {
    background-color: #1a1625;

    .form-control {
      color: #bfbfbf;
    }

    .css-13cymwt-control{
      background-color: #181C32;
      &:focus{
        background-color: #181C32;
      }
    }

    label {
      color: #bfbfbf;
    }

    .filter-content {
      background-color: #1a1625;
    } 
  }

  .react-tagsinput {
    background-color: #1a1625;

    .react-tagsinput-tag {
      color: #fff;
      background-color: #46424f;
    }
  }
  .react-tagsinput-input {
    color: #fff;
  }

  .form-control {
    color: #fff;
  }

  .react-tagsinput {
    background-color: #1a1625;
    &:active {
      background-color: #1a1625;
    }
  }

  .react-select__control{
    background-color: #1a1625;
  }

  .react-select__value-container {
    background-color: #1a1625;
    &:active {
      background-color: #1a1625;
    }
  }

  label {
    color: #bfbfbf;
  }

  .date-filter-container {
    position: relative;
    display: inline-block;
  }

  .date-filter-container::after {
    content: '\f073';
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #bfbfbf; 
  }

  .css-1nmdiq5-menu{
   background-color:#1a1625;
   color: #86B7FE;
   .react-select__input:hover{
   background-color: #1a1625;
   }
  }

  .react-select__input:hover{
    background-color: #1a1625;
       }

       .css-t3ipsp-control{
        background-color: #1a1625;
        &:hover{
        background-color: #1a1625;
       }
      }
}

.dialog-dark{
  .react-tagsinput-tag {
    background-color: #46424f;
    border-color: #CCC;
    border-radius: 0.25rem;
    color: #fff;
}
}