.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f8f9fa; /* Light background color */
  }
  
  .maintenance-content {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    background-color: #ffffff; /* White background color */
  }
  
  .maintenance-title {
    font-size: 32px;
    color: #333333; /* Dark title color */
    margin-bottom: 20px;
  }
  
  .maintenance-message {
    font-size: 18px;
    color: #666666; /* Medium color for message */
  }