* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  -webkit-user-select: text;
  // -khtml-user-select: none;
  -moz-user-select: text;
  // -ms-user-select: text;
  user-select: text;
  overflow: hidden;
}

body {
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica, ubuntu,
    roboto, noto, segoe ui, arial, sans-serif;
}
