.login {
  .form { 
    input {
      line-height: 19.8px;
      width: 250px;
      margin-top: 7px;
    }

    .not-member {
      color: #A1A5B7;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.dark-theme {
  background-color: #2e3339;
  border-radius: 10px;
  .form { 
   color: white;
    
   h1{
      color: white !important;
    }

    label{
      color: white;
    }
    input {
      line-height: 19.8px;
      width: 250px;
      margin-top: 7px;
      color: white;
    }

    .not-member {
      color: #000000;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
