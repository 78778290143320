.edit-user {
    position: absolute;
    inset: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #FFF;

    .header {
        color: #009EF7 !important;
        font-weight: 500;
        font-size: larger;
    }

    .content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        label {
            margin: 20px 0 2px;
        }

        label:first-child {
            margin: 5px 0 2px;
        }

        textarea {
            height: 75px;
            width: 98%;
            resize: none;
        }

        .form-control {
            width: 40%;
        }

        .react-tagsinput {
            border-radius: 0.25rem;
            display: inline-table;
            width: 98%;

            .react-tagsinput-tag {
                background-color: #F5F8FA;
                border-color: #CCC;
                border-radius: 0.25rem;
                color: #181C32;
            }

            .react-tagsinput-input {
                width: auto;
            }
        }

        .react-tagsinput--focused {
            border-color: #86B7FE;
        }
    }  

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .btn {
            margin-left: 10px;
            width: 70px;
        }
    }
}

.edit-user-dark {
    position: absolute;
    inset: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #1e1e2d;

    .header {
        color: #009EF7 !important;
        font-weight: 500;
        font-size: larger;
    }


    .content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        label {
            color: #FFF !important;
            margin: 20px 0 2px;
        }

        label:first-child {
            margin: 5px 0 2px;
        }

        textarea {
            height: 75px;
            width: 98%;
            resize: none;
        }

        .form-control {
            color: #FFF;
            width: 40%;
            & > option{
                background-color: #1e1e2d;
                &:hover{
                    background-color: #46424f;
                }
            }
        }
        .form-control:focus{
            background-color: #1e1e2d;
        }

        .form-control::placeholder {
            color: #cabcbc;
        }

        .react-tagsinput {
            border-radius: 0.25rem;
            display: inline-table;
            width: 98%;

            .react-tagsinput-tag {
                background-color: #F5F8FA;
                border-color: #CCC;
                border-radius: 0.25rem;
                color: #181C32;
            }

            .react-tagsinput-input {
                width: auto;
                color: #FFF;
            }

            .react-tagsinput-input::placeholder {
                color: #cabcbc;
            }
        }

        .react-tagsinput--focused {
            border-color: #86B7FE;
        }
    }  

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .btn {
            margin-left: 10px;
            width: 70px;
        }
    }
}