.more-button {
    .more {
        padding: 1px 4px;
        background-color: #f1fAFF;
        font-size: smaller;
        font-weight: 600;
        color: #009EF7;
    }
}

.no-bullets {
    list-style-type: none;
}

.no-padding {
    padding-inline-start: 0px;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.reduce-margin {
    line-height: 25px;
    // margin-bottom: -20px;
}

.icon {
    position: absolute;
}

.btn-down {
    margin-top: 5px;
    margin-left: 5px;
}

.btn-up {
    // margin-top: -5px;
    margin-left: 5px;
    margin-top: 5px;
}

.link-button {
    padding-left: 0px;
    background: none;
    border: none;
    color: #40b8f9;
    cursor: pointer;
}

.ag-cell[aria-colindex="5"] .icon.btn-up {
    margin-top: 0;
}