.reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: transparent; 
}

.reset-box {
    padding: 30px;
    border-radius: 4px;
    background-color: #ffffff;
}

.reset-box label {
    display: block;
    font-weight: bold;
    color: #333333; /* Label text color */
}

.reset-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: transparent;
    box-shadow: none;
    outline: none;
}

.reset-box input:focus {
    border: 1px solid #9dd1ff;
}

.message-container {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.message-container p,
.reset-box p {
    font-size: 16px;
    color: #666666; /* Medium color for message */
}

.reset-button{
    width: 100%;
    margin-bottom: 20px;
}

.reset-container-dark {
    .reset-box {
        padding: 30px;
        border-radius: 4px;
        background-color: #2e3339;
        width:300px;
        height:300px;
    }

    .reset-box h1,
    .reset-box label,
    .reset-box input {
        color: #ffffff; /* Light text color for dark mode */
    }

    .message-container {
        text-align: center;
        padding: 40px;
        border-radius: 8px;
        background-color: #2e3339; /* Dark background color */
    }

    .message-container p,
    .reset-box p {
        font-size: 16px;
        color: #ffffff; /* Light color for message */
    }

    .reset-h1{
        color: #ffffff  !important;
    }

    .reset-button{
        width: 100%;
        margin-bottom: 20px;
    }
}
