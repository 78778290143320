.light {
  .ag-theme-material {
    --ag-header-background-color: #f5f8fa;
    --ag-borders-critical: dashed 1px;
    --ag-row-border-style: dashed;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .ag-theme-material {
    --ag-material-accent-color: #009ef7 !important;
  }
}

.dark {
  .ag-theme-material {
    --ag-header-background-color: #1e1e2d;
    --ag-borders-critical: solid 1px;
    --ag-row-border-style: solid;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .ag-theme-material {
    --ag-material-accent-color: #009ef7 !important;
  }

  .ag-header-cell-label {
    color: #fff;
    &:hover{
      color: #15171c;
    }
  }

  .ag-row {
    background-color: #1e1e2d;
    color: #fff;
  }

  .ag-root-wrapper {
    background-color: #1e1e2d;
  }
}
